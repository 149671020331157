<template>
<div>
    <div class="display-block">
        <alert-message v-if="msg" :error="msg"></alert-message>
        <page-header v-model="search" title="Customers" permission="createCustomer" @input="updateApiCall(apiCall,search)" @click="navigateTo('/app/customers/0')"></page-header>
        <v-flex text-right>
            <import-file-button importData='customers'></import-file-button>
        </v-flex>
    </div>
    <div>
        <v-data-table :loading="loading" :page="currentPage" @update:page="setCurrentPage($event,lsCurrentPageKey)" 
        :headers="headers" :items="items" class="elevation-1" :search="search" :items-per-page="items.length" hide-default-footer>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deleteCustomer(item._id):$router.push(icon.path+''+item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <customer-card :id="item._id" :name="`${item.firstName+' '+item.lastName}`" :mobileNo="item.mobileNo" :email="item.email" :icons="icons" @delete="removeItem"></customer-card>
            </template>
        </v-data-table>
        <pagination :api="apiCall" ref="pagination" v-model="items" sort="+firstName"></pagination>
    </div>
    <alert-message v-if="msg" :error="msg"></alert-message>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import CustomerCard from '@/components/UserResponsiveCard'
import ImportFileButton from '@/components/ImportFileButton'
export default {
    components: {
        ActionButton,
        CustomerCard,
        ImportFileButton
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "FirstName",
                    value: "firstName"
                },
                {
                    text: "MiddleName",
                    value: "middleName"
                },
                {
                    text: 'LastName',
                    value: 'lastName'
                },
                {
                    text: 'Mobile no',
                    value: 'mobileNo'
                },
                {
                    text: 'E-Mail',
                    value: 'email'
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            customers: [],
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/customers/view/",
                    permission: "viewCustomer"
                }, {
                    name: "mdi-pencil",
                    path: "/app/customers/",
                    permission: "editCustomer"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteCustomer"
                }
            ],
            lsCurrentPageKey: 'customers-current-page',
            loading: false,
            apiCall:appConstants.CUSTOMERS_API+'?userType=CUSTOMER'
        };
    },
    methods: {
        async deleteCustomer(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Customer?", appConstants.CUSTOMERS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        removeItem(id) {
            this.deleteCustomer(id)
        }
    }
};
</script>
